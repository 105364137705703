import React, {useState} from "react";
import { Link } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import logo from "../../img/logo.png";
import { 
  Navbar,
  NavbarBrand,
  Nav,
  DropdownToggle,
  DropdownMenu,
  ButtonDropdown,
  DropdownItem
} from 'reactstrap';

interface propsFromState {

}

type AllProps = propsFromState;

const NavbarMenu: React.FC<AllProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
        <Navbar color="white" light expand="md">
        <NavbarBrand>
            <Link id="RouterNavLink" to="/logout"><FaSignOutAlt size="14" /></Link>
        </NavbarBrand>
          <Nav className="mr-auto" navbar>
          <ButtonDropdown isOpen={isOpen} toggle={toggle}>
              <DropdownToggle nav caret>
                Servicios
              </DropdownToggle>
              <DropdownMenu left="true">
                <DropdownItem>
                  <Link to="/digitacionHub">Operación Hub</Link>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  <Link to="/logout">Salir</Link>
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown >
          </Nav>
          <img src={logo} alt="Bluex" style={{height: '90px', marginTop: '-20px', marginRight: '-16px', marginBottom: '-20px'}}></img>
      </Navbar>

      {children}
    </div>
  );
};

export default NavbarMenu;
