import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, change } from 'redux-form';
import { Button, Col, FormGroup, Row, Container, Label } from 'reactstrap';
import ReduxInput from '../ReduxInput';
import ReduxOsFinder from '../ReduxOsFinder';
import { FaSave } from 'react-icons/fa';
import validate from './validate';
import { validaOS, actualizaPiezaHub, Inicializa } from '../../store/servicios/actions';
import '../../App.css';
import TableOS from './tableOS';
import { ApplicationState } from '../../store';
import { ServicioActionTypes } from '../../store/servicios/types';
import { RequestActualizaPiezaHub } from "../../models";
import SelectEvEx from '../SelectEvEx/SelectEvEx';
import CardHeader from "@material-ui/core/CardHeader";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  urlBaseCubicadorManual
} from "../../api/const";
import { FormLabel, TextField } from '@material-ui/core';
import { EstadoCubicacion } from './estadoCubicacion';
import { doConsultaCubicacion, doToken } from '../../api/os';


interface Props { };

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none"
  }
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}


function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}


export const DigitacionHubPage: React.FC<Props & InjectedFormProps<{}, Props>> = (props: any) => {
  const { handleSubmit } = props;

  const handleKey = (event: any) => {

    var osIn = "";

    if (event.charCode === 0 && event.keyCode === 13) {

      if (props.store.getState().form.digitacionHubForm.values.evento !== "") {
        osIn = event.target.value.trim();
        props.dispatch(change('digitacionHubForm', 'os', osIn));
        event.target.select();
        if (osIn.length === 9 || osIn.length === 10 || osIn.length === 28) {
          props.dispatch(validaOS(props.store.getState().form.digitacionHubForm.values.os.toString()));

        } else {
          props.store.dispatch({
            type: ServicioActionTypes.FETCH_ERROR,
            mensaje: 'LARGO DE OS NO PERMITIDO'
          });
          document.getElementById("os")?.focus();
        }
      } else {
        props.store.dispatch({
          type: ServicioActionTypes.FETCH_ERROR,
          mensaje: 'NO HA SELECCIONADO UN EVENTO / EXCEPCIÓN'
        });
        document.getElementById("cboEvento")?.focus();
      }
    }
  }

  const handleClick = (event: any) => {
    var osIn = "";

    if (props.store.getState().form.digitacionHubForm.values.evento !== "") {
      if (!isNullUndefinedOrEmpty(props.store.getState().form.digitacionHubForm.values.os)) {
        osIn = props.store.getState().form.digitacionHubForm.values.os.toString().trim();
        props.dispatch(change('digitacionHubForm', 'os', osIn));
        if (osIn.length === 9 || osIn.length === 10 || osIn.length === 28) {
          props.dispatch(validaOS(props.store.getState().form.digitacionHubForm.values.os.toString()));
        } else {
          props.store.dispatch({
            type: ServicioActionTypes.FETCH_ERROR,
            mensaje: 'LARGO DE OS NO PERMITIDO'
          });
          document.getElementById("os")?.focus();
        }
      } else {
        props.store.dispatch({
          type: ServicioActionTypes.FETCH_ERROR,
          mensaje: 'NO SE HA INGRESADO OS'
        });

        document.getElementById("os")?.focus();
      }
    } else {
      props.store.dispatch({
        type: ServicioActionTypes.FETCH_ERROR,
        mensaje: 'NO HA SELECCIONADO UN EVENTO / EXCEPCIÓN'
      });
      document.getElementById("cboEvento")?.focus();
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    var eventoIn = [];
    if (e.target.value.trim() !== "") {
      eventoIn = e.target.value.trim().split("|");
      props.dispatch(change('digitacionHubForm', 'evento', eventoIn[0]));
      props.dispatch(change('digitacionHubForm', 'tipo', eventoIn[1]));
      props.dispatch(change('digitacionHubForm', 'observacion', ''));
      props.dispatch(change('digitacionHubForm', 'os', ''));
      props.dispatch(change('digitacionHubForm', 'cliente', ''));
      props.dispatch(change('digitacionHubForm', 'fechaPromesa', ''));
      props.dispatch(change('digitacionHubForm', 'osGraba', ''));
      props.dispatch(change('digitacionHubForm', 'piezas', '1'));
      props.dispatch(change('digitacionHubForm', 'estadoOrdenServicio', ''));
      props.store.getState().servicio.totalOS = 0;
      props.store.getState().servicio.totalPiezas = 0;
      props.store.getState().servicio.data.os = [];
      props.dispatch(Inicializa());
      document.getElementById("os")?.focus();
    }
  }

  const grabarButton = () => {
    if (props.store.getState().form.digitacionHubForm.values.evento !== "") {
      if (grabarOS()) {

        limpiarInput();
      }
    } else {
      props.store.dispatch({
        type: ServicioActionTypes.FETCH_ERROR,
        mensaje: 'NO HA SELECCIONADO UN EVENTO / EXCEPCIÓN'
      });
      document.getElementById("cboEvento")?.focus();
    }
  }

  const grabarOS = () => {
    if (props.store.getState().form.digitacionHubForm.values.osGraba !== undefined &&
      props.store.getState().servicio.loggedin &&
      isNumber(props.store.getState().form.digitacionHubForm.values.piezas)) {
      actualizaPieza();

      return true;
    } else {
      props.store.dispatch({
        type: ServicioActionTypes.FETCH_ERROR,
        mensaje: 'NO SE HA INGRESADO OS o PIEZAS'
      });
      document.getElementById("os")?.focus();
      return false;
    }
  }

  const isNumber = (value: any): boolean => {
    try {
      return ((value !== null) && (value !== '') && !isNaN(Number(value.toString())));
    } catch (Error) {
      console.log(Error);
      return false;
    }
  }

  const isNullUndefinedOrEmpty = (value: any): boolean => {
    var response = false;

    if (value === undefined)
      return true;
    if (value === null)
      return true;
    if (value === "")
      return true;

    return response;
  }

  const actualizaPieza = (): boolean => {


    var evento: string = '';
    var excepcion: string = '';

    if (props.store.getState().form.digitacionHubForm.values.tipo === 'EV') {
      evento = props.store.getState().form.digitacionHubForm.values.evento;
      excepcion = '';
    } else {
      evento = '';
      excepcion = props.store.getState().form.digitacionHubForm.values.evento;
    }

    props.dispatch(change('digitacionHubForm', 'estadoOrdenServicio', props.store.getState().form.digitacionHubForm.values.estadoOrdenServicio));

    const data: RequestActualizaPiezaHub = {
      datosPieza: {
        codigoBase: props.store.getState().login.user.usuario.codigoPosta,
        codigoBodega: props.store.getState().login.user.usuario.codigoBodega,
        tipoBodega: props.store.getState().login.user.usuario.tipoBodega,
        codigoOficina: props.store.getState().login.user.usuario.codigoOficina,
        codigoEvento: evento,
        codigoExcepcion: excepcion,
        codigoFamilia: '1',
        fechaEvEx: '',
        codigoPersona: props.store.getState().login.user.usuario.usuario,
        numeroOS: props.store.getState().servicio.codigoBarra.codigoBarra.ordenServicio,
        cantidadPiezas: props.store.getState().form.digitacionHubForm.values.piezas,
        observacion: props.store.getState().form.digitacionHubForm.values.observacion,
        codigoPieza: props.store.getState().servicio.codigoBarra.codigoBarra.codigoPieza,
        nombreEmbarcador: props.store.getState().servicio.codigoBarra.codigoBarra.nombreEmbarcador,
        estadoOrdenServicio: props.store.getState().form.digitacionHubForm.values.estadoOrdenServicio,
        largoOS: props.store.getState().form.digitacionHubForm.values.largoOS,
        anchoOS: props.store.getState().form.digitacionHubForm.values.anchoOS,
        altoOS: props.store.getState().form.digitacionHubForm.values.altoOS,
        pesoOS: props.store.getState().form.digitacionHubForm.values.pesoOS
      }
    };

    props.store.dispatch(actualizaPiezaHub(data));
    return true;
  }

  const limpiarInput = () => {
    props.dispatch(change('digitacionHubForm', 'observacion', ''));
    props.dispatch(change('digitacionHubForm', 'os', ''));
    props.dispatch(change('digitacionHubForm', 'cliente', ''));
    props.dispatch(change('digitacionHubForm', 'fechaPromesa', ''));
    props.dispatch(change('digitacionHubForm', 'osGraba', ''));
    props.dispatch(change('digitacionHubForm', 'piezas', '1'));
    props.dispatch(change('digitacionHubForm', 'cboEvento', ''));
    props.dispatch(change('digitacionHubForm', 'estadoOrdenServicio', ''));


    document.getElementById("os")?.focus();
  }


  const handleClickCubicacion = async (estadoOs: any, other: any, peso: any) => {

    handleOpen();

  }

  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (

    <>
      <CardHeader title='Operación Hub' style={{ background: '#00B4DB', color: '#fff', textAlign: 'center', borderRadius: '6px 6px 0 0' }} />
      <div className="justify-content-center bx-bg-white">
        <Col sm="12">
          <form onSubmit={handleSubmit} noValidate={false} className="mt-20">
            <SelectEvEx
              id="cboEvento"
              name="cboEvento"
              label="Evento / Excepción"
              evento="PU"
              onSelectChange={handleChange}>
            </SelectEvEx>

            <Field
              name="observacion"
              type="text"
              component={ReduxInput}
              label="Observación"
              placeHolder=""
            />

            <Field
              id="os"
              name="os"
              type="text"
              component={ReduxOsFinder}
              label="OS"
              placeHolder=""
              maxLength="28"
              onKeyPress={handleKey}
              onClick={handleClick}
              autoFocus={true}
            />

            <Field
              name="cliente"
              type="text"
              component={ReduxInput}
              label="Cliente"
              placeHolder=""
              disabled={true}
            />


            <Field
              name="fechaPromesa"
              type="text"
              component={ReduxInput}
              label="Fecha Promesa"
              placeHolder=""
              disabled={true}
            />

            <div className="mt-10 mb-10 pt-10 pl-10 bx-border-cian">
              <Field
                name="osGraba"
                type="text"
                component={ReduxInput}
                label="OS"
                placeHolder=""
                style={{ paddingLeft: '8px' }}
                disabled={true}
              />
              <Field
                name="piezas"
                type="text"
                component={ReduxInput}
                label="Piezas"
                placeHolder=""
                style={{ paddingLeft: '8px' }}
              />
              <Field
                name="estadoOrdenServicio"
                type="text"
                component={ReduxInput}
                label="Estado cubicación"
                placeHolder=""
                style={{ paddingLeft: '8px' }}
                inputStyle={{ color: 'white', fontWeight: 600, backgroundColor: !props.store.getState().form.digitacionHubForm.values.estadoOrdenServicio ? '#e9ecef' : (props.store.getState().form.digitacionHubForm.values.estadoOrdenServicio === 'Cubicada' ? 'lightgreen' : 'lightcoral') }}
                disabled={true}
              />
            </div>

            {/* <div className="m-0 justify-content-center row form-group">
                <div className="p-0 col-5 col-sm-4 col-lg-4">
                  <b style={{ fontSize: '.875rem' }}>Estado cubicación</b>

                </div>
                <div className="col-7 col-sm-8 col-lg-8" style={{ paddingLeft: "8px" }}>
                  <Field
                    name="estadoOrdenServicio"
                    type="text"
                    component="input"
                    label={<EstadoCubicacion loading={false} data={props.store.getState().form.digitacionHubForm.values.estadoOrdenServicio} />}
                    placeHolder=""
                    style={(props.store.getState().form.digitacionHubForm.values.estadoOrdenServicio === "No Cubicada" || props.store.getState().form.digitacionHubForm.values.estadoOrdenServicio === "Orden Parcialmente Completada" || props.store.getState().form.digitacionHubForm.values.estadoOrdenServicio === "Pendiente") ? { background: '#D26466', paddingLeft: '8px', paddingTop: '7px', height: '40px', width: '175px', color: 'white' } : { background: '#ACD1AF', paddingLeft: '8px', paddingTop: '7px', height: '40px', width: '175px', color: 'white' }}
                    fullWidth
                  />
                </div>
              </div>

              <br />


              <div className="m-0 justify-content-center row form-group">
                <div className="p-0 col-5 col-sm-4 col-lg-4"><label className="bx-label col-form-label-sm"></label>
                </div>
                <div className="col-7 col-sm-8 col-lg-8" style={{ paddingLeft: '8px' }}>

                </div>
              </div>
              <br />

              <Button
                block
                color="bx-orange"
                type="button"
                style={{ marginRight: '10px' }}
                onClick={() => grabarButton()}
              >
                Grabar
                &nbsp;
                <FaSave className="button-padding" size={16} />
              </Button>
            </div> */}

            <Col sm="12" lg="8" style={{ margin: '0 auto', padding: '0' }} >
              <Button
                block
                color="bx-orange"
                type="button"
                style={{ marginRight: '10px' }}
                onClick={() => grabarButton()}
              >
                Grabar
                &nbsp;
                <FaSave className="button-padding" size={16} />
              </Button>
            </Col>

            <div className="mt-20 background-div">
              <Container fluid={true}>
                <Row xs="2">
                  <FormGroup row={true} className="m-0 justify-content-center">

                    <Col xs="5" lg="3" className="p-0">
                      <Label size="sm">Cantidad OS</Label>
                    </Col>
                    <Col xs="5" lg="3" className="p-0">
                      <h3 className="text-center">{props.store.getState().servicio.totalOS}</h3>
                    </Col>

                  </FormGroup>
                  <FormGroup row={true} className="m-0 justify-content-center">
                    <Col xs="5" lg="3" className="p-0">
                      <Label size="sm">Cantidad Piezas</Label>
                    </Col>
                    <Col xs="5" lg="3" className="p-0">
                      <h3 className="text-center">{props.store.getState().servicio.totalPiezas}</h3>
                    </Col>
                  </FormGroup>
                </Row>
              </Container>
            </div>
            <div className="mt-10 scrollable-x">
              <TableOS />
            </div>
          </form>
        </Col>
      </div>

      <div>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <div style={modalStyle} className={classes.paper}>
            <Typography variant="h6" id="modal-title">
              Cubicación
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              OS : {props.store.getState().form.digitacionHubForm.values.osGraba}
            </Typography>
            <Typography variant="subtitle2" id="simple-modal-description-2">
              PESO : {props.store.getState().form.digitacionHubForm.values.pesoOS}
            </Typography>
            <Typography variant="subtitle2" id="simple-modal-description-2">
              VOLUMEN : {props.store.getState().form.digitacionHubForm.values.largoOS}
            </Typography>
            <Typography variant="subtitle2" id="simple-modal-description-2">
              PIEZAS ORDEN : {props.store.getState().form.digitacionHubForm.values.altoOS}
            </Typography>
            <Typography variant="subtitle2" id="simple-modal-description-2">
              PIEZAS CUBICADAS : {props.store.getState().form.digitacionHubForm.values.anchoOS}
            </Typography>
            {/* <SimpleModal /> */}
          </div>
        </Modal>


      </div>

    </>
  );
}

const mapStateToProps = ({ servicio }: ApplicationState) => ({
  loading: servicio.loading,
  errors: servicio.errors,
  totalOS: servicio.totalOS,
  totalPiezas: servicio.totalPiezas
});

const mapDispatchToProps = (state: any) => {
  return {
    initialValues: {
      evento: 'PU',
      piezas: '1',
      tipo: 'EV'
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm<{}, Props>({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    form: 'digitacionHubForm',
    validate,
    enableReinitialize: true,
  })(DigitacionHubPage));

