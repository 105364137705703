import React, { useEffect } from "react";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import '../../App.css';
import { Table } from 'reactstrap';
import { Servicio, createEmptyServicio } from "../../store/servicios/types";
import { fetchRequest } from "../../store/servicios/actions";
import Modal from '@material-ui/core/Modal';
import { Button} from 'reactstrap';
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {
    urlBaseCubicadorManual
} from "../../api/const";


interface PropsFromState {
    loading: boolean;
    data: Servicio;
    errors?: string;
}

type AllProps = PropsFromState;

const useStyles = makeStyles(theme => ({
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: "none"
    }
}));

function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}
let tablaOs = "";
let tablaPeso = "";
let tablaCosto = "";
let tablaPiezas = "";
let tablaPiezasCub = "";

export const TableOS: React.FC<AllProps> = ({
                                                loading,
                                                errors,
                                                data,
                                            }) => {
    useEffect(() => {
        fetchRequest();
    }, []);
    if (data === undefined) {
        data = createEmptyServicio();
    }

    const [open, setOpen] = React.useState(false);
    let handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [estadoOs, setEstadoOs] = React.useState( "" );


    const handleClickCubicacionTabla= (os: any,peso:any, costo:any, piezas:any, piezasCub:any) => {
        tablaOs = os;
        tablaPeso = peso;
        tablaCosto = costo;
        tablaPiezas = piezas;
        tablaPiezasCub = piezasCub;

        handleOpen();
    }
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();

    return (

        <Table hover bordered>
            <thead>
            <tr>
                <th className="w100">OS</th>
                <th className="w85">Cant. Piezas</th>
                <th className="w180">Observación</th>
                <th>Cliente</th>
                <th>Estado Cubicación</th>
            </tr>
            </thead>

            <tbody>
            {data.os && data.os.map((each_datarecord, i) => (

                < >


                    {each_datarecord.os.ordenServicio.estadoOrdenServicio === "Cubicada" &&
                        <tr key={i} style={{background : '#ACD1AF'}}>
                            <td><b style={{color:'white'}}>{each_datarecord.os.ordenServicio.numeroOS}</b></td>
                            <td className="text-center"><b style={{color:'white'}}>{each_datarecord.os.ordenServicio.cantidadPiezas}</b></td>
                            <td><b style={{color:'white'}}>{each_datarecord.os.ordenServicio.observacion}</b></td>
                            <td><b style={{color:'white'}}>{each_datarecord.os.ordenServicio.nombreEmbarcador}</b></td>
                            <td><b style={{color:'white'}}>{each_datarecord.os.ordenServicio.estadoOrdenServicio}</b></td>
                        </tr>

                    }
                    {(each_datarecord.os.ordenServicio.estadoOrdenServicio === "Orden Parcialmente Completada" || each_datarecord.os.ordenServicio.estadoOrdenServicio === "Pendiente" || each_datarecord.os.ordenServicio.estadoOrdenServicio === "No Cubicada" ) &&
                        <tr key={i} style={{background : '#D26466'}} >
                            <td><b style={{color:'white'}}>{each_datarecord.os.ordenServicio.numeroOS}</b></td>
                            <td className="text-center"><b style={{color:'white'}}>{each_datarecord.os.ordenServicio.cantidadPiezas}</b></td>
                            <td><b style={{color:'white'}}>{each_datarecord.os.ordenServicio.observacion}</b></td>
                            <td><b style={{color:'white'}}>{each_datarecord.os.ordenServicio.nombreEmbarcador}</b></td>
                            <td><b style={{color:'white'}}>{each_datarecord.os.ordenServicio.estadoOrdenServicio}</b></td>
                        </tr>

                    }


                </>
            ))
            }
            </tbody>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
            >
                <div style={modalStyle} className={classes.paper}>
                    <Typography variant="h6" id="modal-title">
                        Cubicación
                    </Typography>
                    <Typography variant="subtitle1" id="simple-modal-description">
                        OS : {tablaOs}
                    </Typography>
                    <Typography variant="subtitle2" id="simple-modal-description-2">
                        PESO : {tablaPeso}
                    </Typography>
                    <Typography variant="subtitle2" id="simple-modal-description-2">
                        VOLUMEN : {tablaCosto}
                    </Typography>
                    <Typography variant="subtitle2" id="simple-modal-description-2">
                        PIEZAS ORDEN : {tablaPiezas}
                    </Typography>
                    <Typography variant="subtitle2" id="simple-modal-description-2">
                        PIEZAS CUBICADAS : {tablaPiezasCub}
                    </Typography>

                    {/* <SimpleModal /> */}
                </div>
            </Modal>
        </Table>
    );
};


const mapStateToProps = ({ servicio }: ApplicationState) => ({
    loading: servicio.loading,
    errors: servicio.errors,
    data: servicio.data,
    form: 'digitacionHubForm'
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        fetchRequest: () => {
            dispatch(fetchRequest());
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TableOS);
