
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as reducerForm } from 'redux-form';

import { History } from "history";

import { RouterState } from "connected-react-router";

import { LoginState, createEmptyLoginState } from "./login/types";

import { ServicioState, createEmptyServicioState } from "./servicios/types";
import { ServicioReducer } from "./servicios/reducer";

import { LoginReducer } from "./login/reducer";

import { ValidaUsuarioState, createEmptyValidaUsuarioState} from "./validaUsuario/types";
import { ValidaUsuarioReducer } from "./validaUsuario/reducer";

export interface ApplicationState {
  servicio: ServicioState;
  login: LoginState;
  validaUsuario: ValidaUsuarioState;
  router: RouterState;
}

export const createRootReducer = (history: History) =>
  combineReducers({
    servicio: ServicioReducer,
    login: LoginReducer,
    validaUsuario: ValidaUsuarioReducer,
    form: reducerForm,
    router: connectRouter(history)
  });
  
  export const createEmptyApplicationState = (router: RouterState): ApplicationState => ({
    servicio: createEmptyServicioState(),
    login: createEmptyLoginState(),
    validaUsuario: createEmptyValidaUsuarioState(),
    router: router
  });