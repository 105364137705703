import {
    CodigoBarra,
    RequestActualizaPiezaHub,
    ResponseActualizaPiezaHub,
    ResponseEventoExcepcion,
    ReposnseOrdenServicio,
    TokenBx,
    ResponseCubicacion
} from '../models';
import axios from "axios";
import { codigoUsuario, tokenUsuario, urlBaseHub, urlBaseCubicacion, urlToken, granttype, clientid, clientsecret, apikey,apikeyCubicacion,userSso,passSso } from "./const";

axios.interceptors.request.use(request => {
    console.log('Starting Request', request)
    return request
})

axios.interceptors.response.use(response => {
    console.log('Response:', response)
    return response
})

export type ResponseCubicar = {
    error: {
        codigo: number,
        mensaje: string
    }
}

export const doValidaCodigoBarra = (token: string, codigoBarraIn: string): Promise<CodigoBarra> =>
    new Promise((resolve) => {
        //axios.post(urlBaseHub + '/OperacionHubRest/BXHUB002_OperacionHub/validaCodigoBarra', { codigoBarra: codigoBarraIn }, {
        axios.interceptors.request.use (x=>{
            console.log(x)
            return x
        })

        axios.post(urlBaseHub + '/validaCodigoBarra', { codigoBarra: codigoBarraIn }, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'apikey': apikey,
                'Content-Type': 'application/json',
                'codigoUsuario': codigoUsuario,
                'token': tokenUsuario
            }
        }).then((value) => {
            resolve(value.data);
        }).catch((response) => {
            resolve(response.status);
        });
    });

export const doActualizaPiezaHub = (token: string, message: RequestActualizaPiezaHub): Promise<ResponseActualizaPiezaHub> =>
    new Promise((resolve) => {
        //axios.post(urlBaseHub + '/OperacionHubRest/BXHUB002_OperacionHub/actualizaPiezaHub',  message , {
        axios.post(urlBaseHub + '/actualizaPiezaHub', message, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'apikey': apikey,
                'Content-Type': 'application/json',
                'codigoUsuario': codigoUsuario,
                'token': tokenUsuario
            }
        }).then((value) => {
            resolve(value.data);
        }).catch((response) => {
            resolve(response.status);
        });
    });

export const doObtienEventosExcepciones = (token: string): Promise<ResponseEventoExcepcion> =>
    new Promise((resolve) => {
        axios.get(urlBaseHub + '/obtieneEventosExcepciones', {
            headers: {
                'Authorization': 'Bearer ' + token,
                'apikey': apikey,
                'Content-Type': 'application/json',
                'codigoUsuario': codigoUsuario,
                'token': tokenUsuario
            }
        }).then((value) => {
            resolve(value.data);
        }).catch((response) => {
            resolve(response.status);
        });
    });

export const doConsultaOrdenDeServicio = (token: string, ordenServicio: string): Promise<ReposnseOrdenServicio> =>
    new Promise((resolve) => {
        axios.get(urlBaseHub + '/consultaOrdenDeServicio/' + ordenServicio, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'apikey': apikey,
                'Content-Type': 'application/json',
                'codigoUsuario': codigoUsuario,
                'token': tokenUsuario
            }
        }).then((value) => {
            resolve(value.data);
        }).catch((response) => {
            resolve(response.status);
        });
    });

export const doConsultaCubicacion = (token: string, ordenServicio: string): Promise<ResponseCubicacion> =>
    new Promise((resolve) => {
        console.log(apikeyCubicacion +"---"+ token);
        const testURL = urlBaseCubicacion + '/v1/orders/get/'+ ordenServicio;
        axios.get(testURL, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'apikey': apikeyCubicacion,
                'Content-Type': 'application/json'
            }
        }).then((value) => {
            resolve(value.data.data);
        }).catch((response) => {
            resolve(response.status);
        });
    });

export const doToken = (): Promise<TokenBx> =>
    new Promise((resolve) => {
        const params = new URLSearchParams();

        params.append('grant_type', granttype);
        params.append('client_id', clientid);
        //params.append('client_secret', clientsecret);
        params.append('username', userSso);
        params.append('password', passSso);
        axios.post(urlToken, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((value) => {
            resolve(value.data);
        }).catch((response) => {
            resolve(response.status);
        });
    });
