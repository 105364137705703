// export const NEW = 'Nueva Orden';
// export const PARTIALLY_FILLED = 'Orden Parcialmente Completada';
// export const FILLED = 'Orden Completa sin Cubicar';
// export const EXPIRED = 'Orden Cubicada Incompleta';
// export const FINISHED = 'Orden Cubicada';
// export const PENDING = 'Pendiente';

export const CubicacionStatus: Record<string, string> = {
  // NEW: 'Nueva Orden',
  // PARTIALLY_FILLED: 'Orden Parcialmente Completada',
  // FILLED: 'Orden Completa sin Cubicar',
  // EXPIRED: 'Orden Cubicada Incompleta',
  FINISHED: 'Cubicada',
  // PENDING: 'Pendiente',
}