import React from 'react';
import axios from 'axios';
import { FormGroup, Label, Col } from 'reactstrap';
import {
    codigoUsuario,
    tokenUsuario,
    urlBaseHub,
    urlToken,
    granttype,
    clientid,
    clientsecret,
    apikey,
    familia,
    userSso,
    passSso
} from "../../api/const";
import { ResponseEventoExcepcion } from "../../models/os"

export interface Props {
    id: string,
    name: string,
    label: string,
    evento: string,
    onSelectChange: any;
}
export interface TokenBx {
    access_token: string,
    expires_in: number,
    refresh_expires_in: number,
    refresh_token: string,
    token_type: string,
    'not-before-policy': number,
    session_state: string,
    scope: string
}
export interface State { }
class SelectEvEx extends React.Component<Props, State> {
    state = {
        eventos: [{ codigo: '', descripcion: '', tipo: '' }]
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append('grant_type', granttype);
        params.append('client_id', clientid);
        params.append('username', userSso);
        params.append('password', passSso);

        axios.post(urlToken, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((value) => {
            let tokenBx: TokenBx;
            tokenBx = value.data
            axios.get(urlBaseHub + '/obtenerEventosExcepciones/' + familia, {
                headers: {
                    'Authorization': 'Bearer ' + tokenBx.access_token,
                    'apikey': apikey,
                    'Content-Type': 'application/json',
                    'codigoUsuario': codigoUsuario,
                    'token': tokenUsuario
                }
            }).then((value) => {
                var ev: ResponseEventoExcepcion = value.data;
                this.setState({ eventos: ev.listaEventoExcepcion });
            }).catch((error) => {
                console.log(error);
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    render() {
        return (
            <FormGroup row={true} className="m-0 justify-content-center">
                <Col xs="5" sm="4" lg="4" className="p-0">
                    <Label size="sm" className="bx-label">{this.props.label}</Label>
                </Col>
                <Col xs="7" sm="8" lg="8">
                    <select id={this.props.id} name={this.props.name} className="form-control-sm form-control" onChange={this.props.onSelectChange}>
                        {this.state.eventos.map(element => (
                            <option key={element.codigo} value={element.codigo + '|' + element.tipo} selected={(element.codigo === this.props.evento) ? true : false}>{element.codigo}</option>
                        ))}
                    </select>
                </Col>
            </FormGroup>
        );
    }
}

export default SelectEvEx
