import React from "react";
import "./App.css";
import { Provider, connect } from "react-redux";
import { ApplicationState } from "./store";
import { Store } from "redux";
import { History } from "history";
import { ConnectedRouter } from "connected-react-router";
import Routes from "./routes";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

interface MainProps {
  store: Store<ApplicationState>;
  history: History;
}

const App: React.FC<MainProps> = ({ store, history }) => {
  const persistor = persistStore(store);
  console.log("STORE" , store.getState());
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Routes store={store}/>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

export default connect(null)(App);
