import React from 'react';
import { InjectedFormProps } from 'redux-form';
import { Card } from 'reactstrap';
import { connect } from 'react-redux';

import DigitacionHubPage from './digitacionHubPage.component';

interface Props { };

export const Form: React.FC<Props & InjectedFormProps<{}, Props>> = (props: any) => {
    return (
        <Card>
            <DigitacionHubPage {...props} store={props.store} />
        </Card>
    );
}

export default connect(null)(Form);