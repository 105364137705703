import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Servicio, createEmptyServicio } from "../../store/servicios/types";
import { ApplicationState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchRequest } from "../../store/servicios/actions";
import ReactLoading from 'react-loading';
//import CardHeader from "@material-ui/core/CardHeader";

interface PropsFromState {
  loading: boolean;
  data: Servicio;
  errors?: string;
  backgroundTitle?:string
}

interface propsFromDispatch { }

type AllProps = PropsFromState & propsFromDispatch;

const Loading: React.FC<AllProps> = ({
  loading,
  errors,
  data,
  backgroundTitle,
}) => {
  useEffect(() => {
    fetchRequest();
  }, []);

  if (data === undefined) {
    data = createEmptyServicio();
  }

  if (loading) {
  return (
    //<ReactLoading type="bubbles" color="#5cca84" height={'20%'} width={'20%'} />
    <div className="overflow">
      <ReactLoading type="spokes" className="bx-spin" />
    </div>
  );}else {
    return (
      //<CardHeader title='Operación Hub' style={{background: '#39C0E0', color: '#fff', borderRadius: '6px 6px 0 0'}}/>
      <div></div>
    );
  }
};

const mapStateToProps = ({ servicio }: ApplicationState) => ({
  loading: servicio.loading,
  errors: servicio.errors,
  data: servicio.data,
  backgroundTitle: servicio.backgroundTitle,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchRequest: () => {
      dispatch(fetchRequest());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Loading);
