import { CubicacionEntity, OsEntity, CodigoBarra, ResponseEventoExcepcion } from "../../models";

export interface OsEntityRubber {
    id: string,
    os: OsEntity
}

export interface Servicio {
    os: OsEntityRubber[];
}

export interface Eventos {
    eventos: ResponseEventoExcepcion;
}

export enum ServicioActionTypes {
    FETCH_REQUEST_OS = "@@servicio/FETCH_REQUEST_OS",
    FETCH_CUBICA_MASIVO = "@@servicio/FETCH_CUBICA_MASIVO",
    FETCH_SUCCESS = "@@servicio/FETCH_SUCCESS",
    FETCH_ERROR = "@@servicio/FETCH_ERROR",
    SET_OS = "@@servicio/SET_OS",
    LOADING = "@@servicio/LOADING",
    CLEAR_MESSAGE = "@@servicio/CLEAR_MESSAGE",
    CLEAR_DATA = "@@servicio/CLEAR_DATA",

    FETCH_ERROR_OS = "@@servicio/FETCH_ERROR_OS",
    CLEAR_MESSAGE_OS = "@@servicio/CLEAR_MESSAGE_OS",
    FETCH_SUCCESS_OS = "@@servicio/FETCH_SUCCESS_OS",
    LOADING_VALIDA_OS = "@@servicio/LOADING_VALIDA_OS",
    SET_VALIDA_OS = "@@servicio/SET_VALIDA_OS",

    GET_EVENTOS_EXCEPCIONES ="@@servicio/GET_EVENTOS_EXCEPCIONES"
}
export interface ServicioState {
    readonly loading: boolean;
    readonly data: Servicio;
    readonly errors?: string;
    readonly message?: string;
    readonly codigoBarra?: CodigoBarra;
    readonly totalOS?: number;
    readonly totalPiezas?: number;
    readonly backgroundTitle?:string;
    readonly errorOS?:boolean;
    readonly validaOS?:boolean;
    readonly eventos?: ResponseEventoExcepcion
}

export const createEmptyServicio = (): Servicio => ({
    os: []
});

export const createEmptyServicioState = (): ServicioState => ({
    data: createEmptyServicio(),
    loading: false,
    totalOS: 0,
    totalPiezas: 0,
});

export const createServicio = (os:OsEntityRubber[],cubicacion: CubicacionEntity,osSearch:number): Servicio => ({
    os: os
});
