import React from 'react';
import { FormGroup, Input, Label, Col } from 'reactstrap';

const ReduxInput: React.FC = (field: any) => (
    <FormGroup row={true} className="m-0 justify-content-center">
        <Col xs="5" sm="4" lg="4" className="p-0">
            <Label size="sm" className="bx-label">{field.label}</Label>
        </Col>
        <Col xs="7" sm="8" lg="8" style={field.style}>
            <Input
                {...field.input}
                id={field.id}
                type={field.type}
                placeholder={field.placeHolder}
                max={field.maxDate}
                min={field.minDate}
                maxLength={field.maxLength}
                pattern={field.pattern}
                step={field.step}
                disabled={field.disabled}
                onKeyDown={field.onKeyPress}
                onKeyPress={field.onKeyPress}
                onBlur={field.onBlur}
                size="sm"
                autoFocus={field.autoFocus}
                style={field.inputStyle}
            />
        </Col>
    </FormGroup>
);

export default ReduxInput;
