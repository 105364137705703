/*
export const tokenUsuario = "cf4112bfe5fc1076026c81ead8650d8d";
export const codigoUsuario = 1666;
export const familia = 0;
export const urlBaseHub = "https://apigw.bluex.cl/OperacionHubREST";
export const apikey = "sfBdNaBWrErYpwTT2k9alwIFbyCcm5eL";
export const apikeyCubicacion = "kkebeiwbsbXBE1wWh9TFreKIGilw8AFN";
export const clientsecret = "78a38e92-79ef-42c1-81ea-1300bb01de14";
export const urlToken = "https://desa.sso.bluex.cl/auth/realms/hub-mobile/protocol/openid-connect/token";
export const granttype = "password";
export const clientid = "admin-cli";
export const username = "test1";
export const password = "test1";
*/

export const tokenUsuario = process.env.REACT_APP_TOKEN_USUARIO || '';
export const codigoUsuario = process.env.REACT_APP_COD_USUARIO;
export const familia = process.env.REACT_APP_FAMILIA;
export const urlBaseHub = process.env.REACT_APP_URL_BASE || '';
export const urlBaseCubicacion = process.env.REACT_APP_URL_BASE_CUBICACION || '';
export const apikey = process.env.REACT_APP_API_KEY || '';
export const apikeyCubicacion = process.env.REACT_APP_API_KEY_CUBICACION || '';
export const clientsecret = process.env.REACT_APP_CLIENT_SECRET || '';
export const urlToken = process.env.REACT_APP_URL_TOKEN || '';
export const granttype = process.env.REACT_APP_GRANT_TYPE || '';
export const clientid = process.env.REACT_APP_CLIENT_ID || '';
export const urlBaseCubicadorManual = process.env.REACT_APP_CUBICADOR_MANUAL_URL_BASE || '';
export const userSso = process.env.REACT_APP_USER_SSO || '';
export const passSso = process.env.REACT_APP_PWD_SSO|| '';


