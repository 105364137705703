import React from 'react';
import { connect } from 'react-redux';
import Form from './Form';
import { Col } from 'reactstrap';
import { Store } from 'redux';
import { ApplicationState } from '../../store';
import { push } from 'connected-react-router';
import { ServicioAction } from '../../store/servicios/actions';
import { ToastContainer, toast } from 'react-toastify';
import { ServicioActionTypes } from '../../store/servicios/types';
import { reset } from "redux-form";
import beepError from "../../sounds/fail-buzzer-04.mp3";
import beepOs from "../../sounds/beep-once-as.mp3";
import Loading from './loading';

interface MainProps {
  store: Store<ApplicationState>;
}
export const DigitacionHub: React.FC<MainProps> = (props: any) => {

  const errorsound = new Audio(beepError);
  errorsound.volume = 1;

  const beepsound = new Audio(beepOs);
  beepsound.volume = 1;

  const handleSubmit = (values: any) => {

    var ok:boolean = false;
    if (props.store.getState().servicio.data) {
      if (props.store.getState().servicio.data.os !== undefined) {
        if (props.store.getState().servicio.data.os.length > 0) {
          ok = true;
          const success: ServicioAction = {
            type: ServicioActionTypes.CLEAR_MESSAGE
          } 
          props.store.dispatch(success);
          
        }
      }
    }

    if (!ok) {
      props.store.dispatch({
        type: ServicioActionTypes.FETCH_ERROR,
        mensaje: 'NO SE HAN INGRESADO OS'
      });
    }
  }

  props.store.subscribe(() => {
    console.log('STATE: ',props.store.getState());

    if (props.store.getState().servicio.message !== undefined || 
        props.store.getState().servicio.errors !== undefined ||
        props.store.getState().servicio.validaOS !== undefined) {

      if (props.store.getState().servicio.message !== undefined) {
        toast.info(props.store.getState().servicio.message);
      }
      if (props.store.getState().servicio.errors !== undefined) {
        if(props.store.getState().servicio.errorOS){
          errorsound.play();   
        }
        toast.error(props.store.getState().servicio.errors);
      }
      if (props.store.getState().servicio.validaOS) {
        beepsound.play(); 
      }
      const success: ServicioAction = {
        type: ServicioActionTypes.CLEAR_MESSAGE_OS
      }
      props.store.dispatch(success);

    }
  });

  console.log("LOGGED IN", props.store.getState().login.loggedin);
  props.store.dispatch(reset('digitacionHubForm'));

  const success: ServicioAction = {
    type: ServicioActionTypes.CLEAR_DATA
  }
  
  props.store.dispatch(success);

  if (!props.store.getState().login.loggedin) {
    props.store.dispatch(push('/login'));
  }
  
  return (
    <>
      <div><ToastContainer  autoClose={2000}/></div>
      <Loading />
      <Col sm="12" lg="6" style={{ margin: '10px auto 0', paddingLeft: '5px', paddingRight: '5px' }}>
        <Form onSubmit={handleSubmit} isLoading={props.store.getState().servicio.loading} {...props} store={props.store} />
      </Col>
    </>
  );
}

export default connect(null)(DigitacionHub);