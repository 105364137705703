import React from 'react';
import { connect } from 'react-redux';
import Form from './Form';
import { Col } from 'reactstrap';
import { Store } from 'redux';
import { ApplicationState } from '../../store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { push } from 'connected-react-router';
import { reset } from "redux-form";

interface MainProps {
  store: Store<ApplicationState>;
}
export const Individual: React.FC<MainProps> = (props: any) => {
  const handleSubmit = (values: any) => {
    if (props.store.getState().individual.data) {
      if (props.store.getState().individual.data.os !== undefined) {
        if (props.store.getState().individual.data.os.ordenServicio !== undefined) {
          if (props.store.getState().individual.data.os.ordenServicio.numeroOS !== "0") {
            console.log('handleSubmit');
          }
        }
      }
    }
  }

  /*props.store.subscribe(() => {
    if (props.store.getState().individual.message !== undefined || props.store.getState().individual.errors !== undefined) {
      if (props.store.getState().individual.message !== undefined) {
        toast.info(props.store.getState().individual.message);
      }
      if (props.store.getState().individual.errors !== undefined) {
        toast.error(props.store.getState().individual.errors);
      }
    }
  });*/

  props.store.dispatch(reset('individualForm'));

  if (!props.store.getState().login.loggedin) {
    props.store.dispatch(push('/login'));
  }
  return (
    <>
      <div>&nbsp;<ToastContainer /></div>
      <Col sm="11">
        <Form onSubmit={handleSubmit} isLoading={props.store.getState().individual.loading} {...props} store={props.store} />
      </Col>
    </>
  );
}

export default connect(null)(Individual);
