import { FormErrors } from 'redux-form';
import { DigitacionHubParams } from './digitacionHub.d';


const validate = (values: DigitacionHubParams): FormErrors<DigitacionHubParams> => {
    const errors: FormErrors<DigitacionHubParams> = {};

    if (!values.alto) {
      errors.alto = '*';
    }
    
    if (!values.largo) {
      errors.largo = '*';
    }

    if (!values.ancho) {
      errors.ancho = '*';
    }

    if (!values.peso) {
      errors.peso = '*';
    }

    if (!values.piezas) {
      errors.piezas = '*';
    }

    return errors;
};
export default validate;